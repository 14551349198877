import * as React from "react";

import { ContainerFullWidth, Layout, ShowsContainer, Title } from "../components/index";

const Shows = (props) => {
    return (
        <Layout {...props}>
            <ContainerFullWidth>
                <Title>Shows</Title>
                <ShowsContainer />
            </ContainerFullWidth>
        </Layout>
    );
};

export default Shows;